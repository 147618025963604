import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const pourquoi = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Pourquoi" />
    <h3 className='underline-title'>Pourquoi?</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Pourquoi veulent-ils que je sache ce qu'ils sont?<br />
        Pourquoi veulent-ils que je sache ce qu'ils font?<br />
      </p>
      <p>
        Ignorent-ils eux-mêmes ce qu'ils sont?<br />
        Ignorent-ils eux-mêmes ce qu'ils font?<br />
      </p>
      <p>
        Pourquoi veulent-ils que je sache qu'ils sont contents?<br />
        Ne savent-ils pas eux-mêmes qu'ils sont contents?<br />
      </p>
      <p>
        Pourquoi veulent-ils que je clame ma misère?<br />
        Gagnent-ils lorsqu'ils m'entendent claironner ma misère?<br />
      </p>
      <p>
        Pourquoi chantent-ils dès qu'ils m'entendent pleurer?<br />
        Mes larmes sont-elles cause de leur joie?<br />
      </p>
      <p>
        Pourquoi?
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 18 mai 1974</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default pourquoi
